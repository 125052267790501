import {Button, Card, CardSubtitle, CardTitle, Form, FormGroup, Label} from "reactstrap";
import {BarBillConstants} from "../constants/BarBillConstants";
import Dexie from "dexie";
import EventBus from "../libs/EventBus";
import {QuestionModal} from "../modals/QuestionModal";
import {db} from "../models/db";
import {useEffect, useState} from "react";
import {getBoolFromLocalstorageCasted, saveToLocalstorage} from "../libs/LocalStorage";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {motion} from "framer-motion";

export function SettingsPage() {

    const [advanced, setAdvanced] = useState(false)

    useEffect(() => {
        let localstorageCasted = getBoolFromLocalstorageCasted("settings");
        setAdvanced(localstorageCasted);
    }, [])

    const toggleAdvanced = () => {
        let result = !advanced ? "true" : "false";
        setAdvanced(!advanced)
        saveToLocalstorage("settings", result);
    }

    let dropDatabase = () => {
        Dexie.delete("BarBillDB").then(r => window.alert("Database deleted"))
    }

    let clearDatabase = () => {
        db.usedProducts.clear().then(x => {
            db.customProducts.clear().then(r => {
                window.alert("Database cleared")
            })
        });
    }

    return (
        <>
            <QuestionModal executor={dropDatabase} eventBus={"openQuestionModal1"} title={"Reset Database"} question={"Do you want to remove everything in the database?"}/>
            <QuestionModal executor={clearDatabase} eventBus={"openQuestionModal2"} title={"Reset Database"} question={"Do you want to empty used and custom products?"}/>
            <motion.div
                initial={{opacity: 0, display: "none"}}
                animate={{opacity: 1, display: "block"}}
                exit={{opacity: 0, display: "none"}}>
            <Card color={"dark"} inverse body>
                <CardTitle><h2>Settings</h2></CardTitle>
                <CardSubtitle>
                    <Form>
                        <FormGroup>
                            <Label for={"btnResetDatabase"}>
                                Advanced Settings
                            </Label>
                            <br/>
                            <BootstrapSwitchButton checked={advanced} onChange={toggleAdvanced} size={"xs"}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for={"btnResetDatabase"}>
                                Actions
                            </Label>
                        </FormGroup>
                        <FormGroup>
                            <Button id={"btnResetDatabase"} className={"btn-info"} onClick={() => EventBus.dispatch("openQuestionModal2", {})}>Empty Database</Button>
                            {" "}
                            <Button id={"btnResetDatabase"} className={"btn-danger"} onClick={() => EventBus.dispatch("openQuestionModal1", {})}>Reset Database</Button>
                        </FormGroup>
                    </Form>
                </CardSubtitle>
            </Card>
            <br/>
            <Card color={"dark"} inverse body>
                <CardTitle><h2>Credits</h2></CardTitle>
                <CardSubtitle>
                    <p>Developer: <a href={BarBillConstants.DeveloperUrl} target={"_blank"} rel={"noreferrer"}>{BarBillConstants.Developer}</a></p>
                    <p>Author: <a href={BarBillConstants.AuthorUrl} target={"_blank"} rel={"noreferrer"}>{BarBillConstants.Author}</a></p>
                </CardSubtitle>
            </Card>
            </motion.div>
        </>
    )
}
