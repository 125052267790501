import {
    Badge,
    Collapse, Container,
    Nav,
    Navbar,
    NavbarBrand, NavbarText,
    NavbarToggler,
    NavItem,
    NavLink
} from "reactstrap";
import {Outlet, NavLink as RRNavLink, Link} from "react-router-dom";
import {useState} from "react";
import {BarBillConstants} from "../constants/BarBillConstants";
import { CSSTransition } from "react-transition-group";

export function CustomNavbar() {

    const [isOpen, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!isOpen)
    }

    return (
        <div className={"app"}>
            {/* eslint-disable-next-line no-restricted-globals */}
            <Navbar color="dark" container dark expand="sm" fixed={"top"} className={"bd-header border-dark"}>
                <NavbarBrand href="/">
                    {BarBillConstants.Title}
                </NavbarBrand>
                <NavbarToggler onClick={toggle}/>
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="me-auto" navbar>
                        <NavItem>
                            <NavLink to="/" className="nav-link" tag={RRNavLink}>
                                Home
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/products" className="nav-link" tag={RRNavLink}>
                                Products
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/settings" className="nav-link" tag={RRNavLink}>
                                Settings
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <NavbarText>
                        <Link to="/changelog"><Badge>{BarBillConstants.Version}</Badge></Link>
                    </NavbarText>
                </Collapse>
            </Navbar>
            <main>
                <Container className={"app-content"}>
                    <Outlet/>
                </Container>
            </main>
        </div>
    )
}
