import {getBoolean} from "./Utils";

export const getStringFromLocalstorage = (name: string) => {
    const data = localStorage.getItem(name)
    return data;
}

export function getBoolFromLocalstorageCasted(name: string){
    const data = getStringFromLocalstorage(name)
    return getBoolean(data);
}

export const saveToLocalstorage = (name: string, data: string) => {
    localStorage.setItem(name, data);
}
