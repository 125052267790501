import {Badge, Card, CardSubtitle, CardTitle, Col} from "reactstrap";
import {faPlus, faMinus, faTrashAlt, faPen, faPencil} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CustomProduct} from "../models/CustomProduct";
import {db} from "../models/db";
import EventBus from "../libs/EventBus";
import {useEffect, useState} from "react";
import {EditCustomProductModal} from "../modals/EditCustomProductModal";
import {Product} from "../models/Product";

type customProductPropTypes = {
    isEdit: boolean
    product: CustomProduct
    productId?: number
};

export function CustomProductView({product,productId, isEdit}: customProductPropTypes) {
    const [count, setCount] = useState<number>(0)

    useEffect(() => {
        if(!isEdit){
            db.getCountFromCustomProductId(productId as number).then((res) => setCount(res as number));
        }
    })
    let execute = (command: Function) => {
        command()
        if(!isEdit){
            db.getCountFromCustomProductId(productId as number).then((res) => setCount(res as number));
            EventBus.dispatch("productsChanged", "")
        }
    }

    let openCustomProductModal = (product: CustomProduct) => {
        if(productId !== undefined){
            EventBus.dispatch("openEditCustomProductModal", product)
        }
    }

    return (
        <Col xs={12} sm={6} md={5} lg={4} xl={3} xxl={3} key={productId} style={{padding: 2}}>
            <Card color={"dark"} inverse body>
                <CardTitle tag="h5">
                    <>
                        <>{product.name} <span className={"fa-pull-right"}>{product.emoticon}</span></>
                        {!isEdit &&
                            <>
                                <br/>
                                <br/>
                                <FontAwesomeIcon icon={faMinus}
                                                 onClick={() => execute(() => db.decreaseCustomProduct(productId as number))}
                                                 title="Remove product" width={25} className={"text-primary"}
                                                 style={{cursor: "pointer"}}/>
                                &nbsp;
                                <Badge pill={true}>{count}</Badge>
                                &nbsp;
                                <FontAwesomeIcon icon={faPlus}
                                                 onClick={() => execute(() => db.increaseCustomProduct(productId as number))}
                                                 title="Add product" width={25} className={"text-primary"}
                                                 style={{cursor: "pointer"}}/>
                            </>
                        }
                        {isEdit &&
                            <>
                                <br/>
                                <FontAwesomeIcon icon={faTrashAlt}
                                                 onClick={() => execute(() => db.removeCustomProduct(productId as number))}
                                                 title="Delete product" width={25} className={"text-danger"}
                                                 style={{cursor: "pointer"}}/>
                                <FontAwesomeIcon icon={faPencil}
                                                 onClick={() => execute(() => openCustomProductModal(product))}
                                                 title="Edit product" width={25} className={"text-primary"}
                                                 style={{cursor: "pointer"}}/>
                            </>
                        }
                    </>
                </CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                    <>
                        {isEdit &&
                            <>€ {product.price.toPrecision(3)}</>
                        }
                    </>

                </CardSubtitle>
            </Card>
        </Col>
    )
}
