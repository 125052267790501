import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useEffect, useState} from "react";
import EventBus from "../libs/EventBus";
import {db} from "../models/db";
import {getBoolFromLocalstorageCasted} from "../libs/LocalStorage";
import Picker from 'emoji-picker-react';
import {CustomProduct} from "../models/CustomProduct";
import {Product} from "../models/Product";

export function EditCustomProductModal() {
    const [product, setProduct] = useState<CustomProduct|Product>({count: 0, id: 0, name: "empty", emoticon: "😊", price: 0.0});
    const [show, setShow] = useState(false);
    const [value, setValue] = useState(0.00);
    const [id, setId] = useState(0);
    const [name, setName] = useState("");
    const [emoticon, setEmoticon] = useState("🍕");
    const [emojiPicker, setEmojiPicker] = useState(false);
    const [isAdvanced, setAdvanced] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [valueError, setValueError] = useState(false);

    let handleClose = () => {
        setShow(false);
        resetModal()
    };

    let handleShow = (props: CustomProduct|Product) => {
        setProduct(props);
        setShow(true);
    };

    useEffect(() => {
        setEmoticon(product.emoticon as string)
        setName(product.name as string)
        setValue(product.price as number)
        setId(product.id as number)
        EventBus.on("openEditCustomProductModal", (props: CustomProduct|Product) => {
            handleShow(props);
        });
        setAdvanced(getBoolFromLocalstorageCasted("settings"));
    }, [handleShow, product.emoticon, product.id, product.name, product.price])

    let resetModal = () => {
        setEmojiPicker(false);
        setEmoticon("😊")
        setName("")
        setValue(0.00)
        setValueError(false)
        setNameError(false)
    }

    let isCustomProduct = (obj: any) => {
        return obj.count !== undefined;
    }

    let updateInDatabase = () => {
        checkErroredForm()
        if(value > 0){
            if(!isCustomProduct(product)){
                db.updateProduct({id: id, emoticon: emoticon, price: value, name: name})
            }
            if(isCustomProduct(product)){
                db.updateCustomProduct({id: id, emoticon: emoticon, price: value, name: name, count: (product as CustomProduct).count})
            }

            handleClose()
        }
        EventBus.dispatch("productsChanged", "")
    }

    let checkErroredForm = () => {
        if(isAdvanced){
            if(name === ""){
                setNameError(true);
            }
        }
        if(value === 0 || value < 0){
            setValueError(true)
        }
    }

    let toggleEmojiPicker = () => {
        setEmojiPicker(!emojiPicker)
    }

    return (
        <div>
            <Modal isOpen={show} fade={true }  toggle={handleClose}>
                <ModalHeader toggle={handleClose}>Edit a product</ModalHeader>
                <ModalBody>
                    <Form>
                        {isAdvanced &&
                        <>
                            <FormGroup>
                                <Label for={"name"}>Whats the name? <span className={"red-text"}>*</span></Label>
                                <Input invalid={nameError} id={"name"} type={"text"} onChange={ value => { let p = product; p.name =value.target.value; setProduct(p); setName(product.name as string)}} value={name} key={name}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for={"emoticon"}>Whats the emoji?</Label>
                                <br/>
                                <button id={"emoticon"} onClick={(e) => { e.preventDefault(); toggleEmojiPicker()}}>{emoticon}</button>
                                {emojiPicker &&
                                    <Picker key={emoticon} onEmojiClick={(event, data) => { let p = product; p.emoticon = data.emoji; setProduct(p); setEmoticon(product.emoticon as string); setEmojiPicker(false)}}/>
                                }
                            </FormGroup>
                        </>
                        }
                        <FormGroup>
                            <Label for={"price"}>Whats the price? <span className={"red-text"}>*</span></Label>
                            <Input invalid={valueError} id={"price"} type={"number"} step={0.10} onChange={(value) => { let p = product; p.price = parseFloat(value.target.value); setProduct(p); setValue(product.price)}} value={value} key={value}/>
                        </FormGroup>
                        <small><small className={"red-text"}>*</small> is required</small>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleClose}>Cancel</Button>{' '}
                    <Button onClick={updateInDatabase} className={"btn-success"}>Save</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
