import {db} from "../models/db";
import {useLiveQuery} from "dexie-react-hooks";
import {ProductView} from "../components/ProductView";
import {Button, Row} from "reactstrap";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CustomProductView} from "../components/CustomProductView";
import {CustomProductModal} from "../modals/CustomProductModal";
import EventBus from "../libs/EventBus";
import {motion} from "framer-motion";
import {EditCustomProductModal} from "../modals/EditCustomProductModal";

export function ProductsPage() {
    const products = useLiveQuery(() => db.products.toArray());
    const customProducts = useLiveQuery(() => db.customProducts.toArray());
    let openCustomProductModal = () => {
        EventBus.dispatch("openCustomProductModal", null)
    }
    if (!products || !customProducts) return null;
    return (
        <>
            <CustomProductModal/>
            <EditCustomProductModal />
            <motion.div
                initial={{opacity: 0, display: "none"}}
                animate={{opacity: 1, display: "block"}}
                exit={{opacity: 0, display: "none"}}>
            <h4>Products</h4>
            <Row>
                {products.map((product, index) => (
                    <ProductView product={product} productId={product.id} key={index} isEdit={true}/>
                ))}
            </Row>

            {customProducts.length > 0 &&
                <>
                    <br/>
                    <h4>Custom Products</h4>
                    <Row>
                        {customProducts.map((product, index) => (
                            <CustomProductView product={product} productId={product.id} key={index} isEdit={true}/>
                        ))}
                    </Row>
                </>
            }
            </motion.div>
            <Button className={"button-bottom-right"} onClick={openCustomProductModal}>
                <FontAwesomeIcon icon={faPlus} width={25} title={"Add product"}/>
            </Button>
        </>
    )
}
