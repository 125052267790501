import React from 'react';

import 'bootstrap-dark-5/dist/css/bootstrap-dark.min.css';
import './App.css';
import RouterProvider from "./router";

export default function App() {
    return (
        <div className="App">
            <RouterProvider/>
        </div>
    );
}
