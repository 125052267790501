import { db } from "./db";

export async function populate() {

    await db.products.bulkAdd([
        {
            name: "Beer",
            emoticon: "🍺",
            price: 2.5
        },
        {
            name: "Soda",
            emoticon: "🥤",
            price: 2.5
        },
        {
            name: "Coffee",
            emoticon: "☕",
            price: 2.5
        },
        {
            name: "Cocktail",
            emoticon: "🍹",
            price: 9.0
        },
        {
            name: "Coffee Special",
            emoticon: "☕🥃",
            price: 7.5
        },
        {
            name: "Shooter",
            emoticon: "💉",
            price: 3.5
        },
        {
            name: "Water",
            emoticon: "💧",
            price: 2.5
        }
        ]);
}
