import {HomePage} from "../pages/HomePage";
import {ProductsPage} from "../pages/ProductsPage";
import {SettingsPage} from "../pages/SettingsPage";
import {NoMatch} from "../pages/NoMatch";
import {ChangeLogPage} from "../pages/ChangelogPage";

const routes = [
    {
        path: "/",
        component: <HomePage />,
    },
    {
        path: "products",
        component: <ProductsPage />,
    },
    {
        path: "settings",
        component: <SettingsPage />,
    },
    {
        path: "changelog",
        component: <ChangeLogPage />,
    },
    { path: "*", component: <NoMatch /> },
];

export default routes;
