import {useEffect, useState} from "react";
import EventBus from "../libs/EventBus";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

type QuestionModalPropTypes = {
    title: string,
    question: string,
    eventBus: string,
    executor: Function
}

export function QuestionModal({title, question, eventBus, executor}: QuestionModalPropTypes){
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        EventBus.on(eventBus, () => {
            handleShow()
        })
    }, [eventBus, handleShow])

    let execute = (executor: Function) => {
        executor();
        handleClose();
    }

    return (
        <div>
            <Modal isOpen={show} fade={true }  toggle={handleClose}>
                <ModalHeader toggle={handleClose}>{title}</ModalHeader>
                <ModalBody>
                    {question}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleClose}>No</Button>{' '}
                    <Button onClick={() => execute(executor)} className={"btn-success"}>Yes</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
