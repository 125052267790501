import {db} from "../models/db";
import {Button, Card, CardTitle, Row} from "reactstrap";
import {ProductView} from "../components/ProductView";
import {useLiveQuery} from "dexie-react-hooks";
import {useEffect, useState} from "react";
import EventBus from "../libs/EventBus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {CustomProductModal} from "../modals/CustomProductModal";
import {CustomProductView} from "../components/CustomProductView";
import {motion} from "framer-motion";

export function HomePage() {
    const [total, setTotal] = useState(0)
    const products = useLiveQuery(() => db.products.toArray());
    const customProducts = useLiveQuery(() => db.customProducts.toArray());

    useEffect(() => {
        db.getTotalPriceUsedProducts().then(res => setTotal(res))
        EventBus.on("productsChanged", () => {
            db.getTotalPriceUsedProducts().then(res => setTotal(res))
        })
    }, [])

    let openCustomProductModal = () => {
        EventBus.dispatch("openCustomProductModal", null)
    }

    if (!products || !customProducts) return null;

    return (
        <>
            <motion.div
                initial={{opacity: 0, display: "none"}}
                animate={{opacity: 1, display: "block"}}
                exit={{opacity: 0, display: "none"}}>
                <CustomProductModal/>
                <Row>
                    <Card color={"dark"} inverse body>
                        <CardTitle tag="h5">Total: &euro;{total.toFixed(2)}</CardTitle>
                    </Card>
                </Row>
                <Row>
                    {products.map((product, index) => (
                        <ProductView product={product} productId={product.id} key={index} isEdit={false}/>
                    ))}
                </Row>
                <Row>
                    {customProducts.map((product, index) => (
                        <CustomProductView product={product} productId={product.id} key={index} isEdit={false}/>
                    ))}
                </Row>
            </motion.div>
            <Button className={"button-bottom-right"} onClick={openCustomProductModal}>
                <FontAwesomeIcon icon={faPlus} width={25} title={"Add product"}/>
            </Button>
        </>
    )
}
