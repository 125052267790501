import {motion} from "framer-motion";

export function NoMatch() {
    return (
        <motion.div
            initial={{opacity: 0, display: "none"}}
            animate={{opacity: 1, display: "block"}}
            exit={{opacity: 0, display: "none"}}>
            <p style={{textAlign: "center"}}>
                <h1>404</h1>
                <h2>Look like you're lost</h2>
                <h3>the page you are looking for is not available</h3>
            </p>
        </motion.div>)
}
