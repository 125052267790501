import {Product} from "../models/Product";
import {Badge, Card, CardSubtitle, CardTitle, Col} from "reactstrap";
import {db} from "../models/db";
import {faPlus, faMinus, faTrashAlt, faPencil} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useState} from "react";
import EventBus from "../libs/EventBus";
import {CustomProductModal} from "../modals/CustomProductModal";
import { EditCustomProductModal } from "../modals/EditCustomProductModal";

type propTypes = {
    product: Product,
    productId?: number,
    isEdit: boolean
};

export function ProductView({product, productId, isEdit}: propTypes) {
    const [count, setCount] = useState<number>(0)

    useEffect(() => {
        if(!isEdit){
            db.getCountFromProductId(productId as number).then((res) => setCount(res as number));
        }
    })

    let execute = (command: Function) => {
        command()
        if(!isEdit){
            db.getCountFromProductId(productId as number).then((res) => setCount(res as number));
            EventBus.dispatch("productsChanged", "")
        }
    }

    let openCustomProductModal = (product: Product) => {
        if(productId !== undefined){
            EventBus.dispatch("openEditCustomProductModal", product)
        }
    }

    let getCount = () => {
        if (count === undefined || count === 0) {
            return 0
        }
        return count
    }

    return (
            <Col xs={12} sm={6} md={5} lg={4} xl={3} xxl={3} key={productId} style={{padding: 2}}>
                <Card color={"dark"} inverse body>
                    <CardTitle tag="h5">
                        <>

                            <>{product.name} <span className={"fa-pull-right"}>{product.emoticon}</span></>
                            {!isEdit &&
                                <>
                                    <br/>
                                    <br/>
                                    <FontAwesomeIcon icon={faMinus}
                                                     onClick={() => execute(() => db.removeProductFromUsedList(productId))}
                                                     title="Remove product" width={25} className={"text-primary"}
                                                     style={{cursor: "pointer"}}/>
                                    &nbsp;
                                    <Badge pill={true}>{getCount()}</Badge>
                                    &nbsp;
                                    <FontAwesomeIcon icon={faPlus}
                                                     onClick={() => execute(() => db.addProductToUsedList(productId))}
                                                     title="Add product" width={25} className={"text-primary"}
                                                     style={{cursor: "pointer"}}/>
                                </>
                            }
                            {isEdit &&
                                <>
                                    <br/>
                                    <FontAwesomeIcon icon={faTrashAlt}
                                                     onClick={() => execute(() => db.deleteProductFromList(productId))}
                                                     title="Delete product" width={25} className={"text-danger"}
                                                     style={{cursor: "pointer"}}/>
                                    <FontAwesomeIcon icon={faPencil}
                                                     onClick={() => execute(() => openCustomProductModal(product))}
                                                     title="Edit product" width={25} className={"text-primary"}
                                                     style={{cursor: "pointer"}}/>
                                </>
                            }
                        </>
                    </CardTitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h6">
                        <>
                            {isEdit &&
                                <>€ {product.price.toPrecision(3)}</>
                            }
                        </>

                    </CardSubtitle>
                </Card>
            </Col>

    )
}
