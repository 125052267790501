import {Badge, Card, CardSubtitle, CardTitle} from "reactstrap";
import {motion} from "framer-motion";

export function ChangeLogPage() {

    return (
        <>
            <motion.div
                initial={{opacity: 0, display: "none"}}
                animate={{opacity: 1, display: "block"}}
                exit={{opacity: 0, display: "none"}}>
                <Card color={"dark"} inverse body>
                    <CardTitle><h2>Changelog</h2></CardTitle>
                    <CardSubtitle>
                        <p><Badge>v0.3</Badge></p>
                        <ul>
                            <li>Out of beta</li>
                            <li>Edit products</li>
                        </ul>
                        <p><Badge>v0.2-beta</Badge></p>
                        <ul>
                            <li>Animated transitions</li>
                            <li>Optimizing navigation</li>
                        </ul>
                        <p><Badge>v0.1-beta</Badge></p>
                        <ul>
                            <li>Initial release</li>
                        </ul>
                    </CardSubtitle>
                </Card>
            </motion.div>
        </>
    )
}
