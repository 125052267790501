import Dexie, {Table} from 'dexie';
import {Product} from './Product';
import {populate} from "./populate";
import {UsedProduct} from "./UsedProduct";
import {CustomProduct} from "./CustomProduct";

export class ProductsDB extends Dexie {
    products!: Table<Product, number>;
    usedProducts!: Table<UsedProduct, number>;
    customProducts!: Table<CustomProduct, number>;

    constructor() {
        super('BarBillDB');
        this.version(3).stores({
            products: '++id',
            usedProducts: '++id, productId',
            customProducts: "++id"
        });
    }

    /**
     * ProductsPage
     */

    /**
     * Add a product
     * @param name
     * @param price
     * @param emoticon
     */
    addProductToList(name: string, price: number, emoticon: string = "") {
        return this.transaction('rw', this.products, () => {
            this.products.add({name: name, price: price, emoticon: emoticon});
        });
    }

    /**
     * Removes a product
     * @param productId
     */
    deleteProductFromList(productId?: number) {
        return this.transaction('rw', this.products, () => {
            this.products.where({id: productId}).delete();
        });
    }

    /**
     * Used ProductsPage
     */

    /**
     * Add product to used list
     * @param productId
     */
    addProductToUsedList(productId: number | undefined) {
        return this.transaction('rw', this.usedProducts, this.products, () => {
            this.products
                .where("id")
                .equals(productId as number)
                .first()
                .then((data) => {
                    this.usedProducts
                        .where("productId")
                        .equals(data?.id as number)
                        .first()
                        .then((usedProduct) => {
                            if (usedProduct !== undefined) {
                                this.usedProducts
                                    .where("productId")
                                    .equals(data?.id as number)
                                    .first()
                                    .then(value => {
                                        if (value !== undefined) {
                                            value.count += 1;
                                            this.usedProducts.put(value);
                                        }
                                    })
                            } else {
                                this.usedProducts.add({productId: productId as number, count: 1});
                            }
                        })

                });
        });
    }

    /**
     * Remove a product from used list
     * @param productId
     * @param removeAll
     */
    removeProductFromUsedList(productId: number | undefined, removeAll: boolean = false) {
        return this.transaction('rw', this.usedProducts, () => {
            if (removeAll) {
                this.usedProducts.where("productId").equals(productId as number).delete();
            } else {
                let usedProduct = this.usedProducts.where("productId").equals(productId as number).first();
                if (usedProduct !== undefined) {
                    usedProduct.then(value => {
                        if (value !== undefined && value.count !== 0) {
                            value.count -= 1;
                            this.usedProducts.put(value);
                        }
                    })
                }
            }
        });
    }

    /**
     * Get count from product
     * @param productId
     */
    async getCountFromProductId(productId: number | undefined) {
        return this.transaction('rw', this.usedProducts, () => {
            return this.usedProducts.where("productId").equals(productId as number).first().then((data) => data?.count)
        });
    }

    async getTotalPriceForProductId(productId: number | undefined) {
        return this.transaction('rw', this.usedProducts, this.products, () => {
            return this.usedProducts
                .where("productId")
                .equals(productId as number)
                .first()
                .then((data) => {
                    return this.products
                        .where("id")
                        .equals(productId as number)
                        .first()
                        .then(res => {
                            return (res?.price as number) * (data?.count as number)
                        })
                });

        });
    }

    async getTotalPriceUsedProducts() {
        return this.transaction('rw', this.usedProducts, this.products, this.customProducts, () => {
            let total = 0;
            return this.usedProducts
                .each(usedProduct => {
                    this.products
                        .where("id")
                        .equals(usedProduct.productId)
                        .first()
                        .then(product => {total += (usedProduct.count) * (product?.price as number)})
                })
                .then(y => {
                    return this.customProducts
                        .each(cProduct => total += (cProduct.count) * (cProduct.price)).then(x => total)
                })
        });
    }

    addCustomProduct(price: number, name: string | undefined, emoticon: string | undefined){
        // eslint-disable-next-line no-mixed-operators
        if(name === undefined || name === null && emoticon === undefined || emoticon === null){
            return this.transaction('rw', this.customProducts, () => {
                return this.customProducts.add({count: 1, price: price, name: "Custom Product", emoticon: ""})
            })
        }
        return this.transaction('rw', this.customProducts, () => {
            return this.customProducts.add({count: 1, price: price, name: name, emoticon: emoticon})
        })
    }

    updateCustomProduct(product: CustomProduct){
        return this.transaction('rw', this.customProducts, () => {
            return this.customProducts.update(product.id as number, product);
        })
    }

    updateProduct(product: Product){
        return this.transaction('rw', this.products, () => {
            return this.products.update(product.id as number, product);
        })
    }

    removeCustomProduct(id: number){
        return this.transaction('rw', this.customProducts, () => {
            return this.customProducts.where("id").equals(id).delete()
        })
    }

    increaseCustomProduct(id: number){
        return this.transaction('rw', this.customProducts, () => {
            this.customProducts.where("id").equals(id).first().then(customProduct => {
                if(customProduct !== undefined){
                    customProduct.count += 1;
                    this.customProducts.put(customProduct);
                }
            })
        })
    }

    decreaseCustomProduct(id: number){
        return this.transaction('rw', this.customProducts, () => {
            this.customProducts.where("id").equals(id).first().then(customProduct => {
                if(customProduct !== undefined && customProduct.count !== 0){
                    customProduct.count -= 1;
                    this.customProducts.put(customProduct);
                }
            })
        })
    }

    getCountFromCustomProductId(id: number){
        return this.transaction('rw', this.customProducts, () => {
            return this.customProducts.where("id").equals(id).first().then(customProduct => customProduct?.count as number )
        })
    }

    getProductById(id: number){
        let productHit: Product|CustomProduct|undefined = undefined;
        let product = this.products.where("productId").equals(id as number).first();
        let customproduct = this.customProducts.where("productId").equals(id as number).first();
        if(product !== null){
            product.then(value => productHit = value);
        }
        if(customproduct !== null){
            customproduct.then(value => productHit = value);
        }

        return productHit;
    }
}

export const db = new ProductsDB();

db.on('populate', populate);

export function resetDatabase() {
    return db.transaction('rw', db.products, db.usedProducts, async () => {
        await Promise.all(db.tables.map(table => table.clear()));
        await populate();
    });
}
