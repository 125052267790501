import React from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import {CustomNavbar} from "../elements/CustomNavbar";
import routes from "./Routes";
import {AnimatePresence} from "framer-motion"

function AnimatedRoutes() {
    const location = useLocation();
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path={"/"} element={<CustomNavbar/>}>
                    {routes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.component}/>
                    ))}
                </Route>
            </Routes>
        </AnimatePresence>
    );
}

export default AnimatedRoutes;
