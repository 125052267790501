import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useEffect, useState} from "react";
import EventBus from "../libs/EventBus";
import {db} from "../models/db";
import {getBoolFromLocalstorageCasted} from "../libs/LocalStorage";
import Picker from 'emoji-picker-react';

export function CustomProductModal() {
    const [show, setShow] = useState(false);
    const [value, setValue] = useState(0.00);
    const [name, setName] = useState("");
    const [emoticon, setEmoticon] = useState("🍕");
    const [emojiPicker, setEmojiPicker] = useState(false);
    const [isAdvanced, setAdvanced] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [valueError, setValueError] = useState(false);

    let handleClose = () => {
        setShow(false);
        resetModal()
    };

    let handleShow = () => {
        setShow(true);
        resetModal()
    };

    useEffect(() => {
        EventBus.on("openCustomProductModal", () => {
            handleShow();
        });
        setAdvanced(getBoolFromLocalstorageCasted("settings"));
    }, [handleShow])

    let resetModal = () => {
        setEmojiPicker(false);
        setEmoticon("😊")
        setName("")
        setValue(0.00)
        setValueError(false)
        setNameError(false)
    }

    let addToDatabase = () => {
        checkErroredForm()
        if(value > 0 && !isAdvanced){
            db.addCustomProduct(value, undefined, undefined)
            handleClose()
        }
        if(value > 0 && name !== "" && isAdvanced){
            db.addCustomProduct(value, name, emoticon)
            handleClose()
        }
        EventBus.dispatch("productsChanged", "")
    }

    let checkErroredForm = () => {
        if(isAdvanced){
            if(name === ""){
                setNameError(true);
            }
        }
        if(value === 0 || value < 0){
            setValueError(true)
        }
    }

    let toggleEmojiPicker = () => {
        setEmojiPicker(!emojiPicker)
    }

    return (
        <div>
            <Modal isOpen={show} fade={true }  toggle={handleClose}>
                <ModalHeader toggle={handleClose}>Add a product</ModalHeader>
                <ModalBody>
                    <Form>
                        {isAdvanced &&
                        <>
                            <FormGroup>
                                <Label for={"name"}>Whats the name? <span className={"red-text"}>*</span></Label>
                                <Input invalid={nameError} id={"name"} type={"text"} onChange={ value => { setName(value.target.value)}}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for={"emoticon"}>Whats the emoji?</Label>
                                <br/>
                                <button id={"emoticon"} onClick={(e) => { e.preventDefault(); toggleEmojiPicker()}}>{emoticon}</button>
                                {emojiPicker &&
                                    <Picker onEmojiClick={(event, data) => {setEmoticon(data.emoji); setEmojiPicker(false)}}/>
                                }
                            </FormGroup>
                        </>
                        }
                        <FormGroup>
                            <Label for={"price"}>Whats the price? <span className={"red-text"}>*</span></Label>
                            <Input invalid={valueError} id={"price"} type={"number"} step={0.50} onChange={(value) => setValue(parseFloat(value.target.value))}/>
                        </FormGroup>
                        <small><small className={"red-text"}>*</small> is required</small>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleClose}>Cancel</Button>{' '}
                    <Button onClick={addToDatabase} className={"btn-success"}>Ok</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
