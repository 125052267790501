export function getBoolean(value:any){
    if (value === undefined || value === null) return false;
    switch(value.toLowerCase()){
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default:
            return false;
    }
}
